import {environment} from '@environments/environment';

export class LoggerService {

  static log(value: any, ...rest: any[]): void {
    if (!environment.production) {
      console.log(value, ...rest);
    }
  }

  static error(error: any): void {
    console.error(error);
  }

  static warn(value: any, ...rest: any[]): void {
    if (!environment.production) {
      console.warn(value, ...rest);
    }
  }

  static table(value: any): void {
    if (!environment.production) {
      console.table(value);
    }
  }
}
