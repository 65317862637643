import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { BreadCrumb } from '@client-models/breadcrumb.model';
import { of } from 'rxjs';

export const BreadcrumbResolveFn: ResolveFn<Array<BreadCrumb>> = (
  route: ActivatedRouteSnapshot
) => {
  const breadcrumbs: Array<BreadCrumb> = buildBreadCrumb(route).reverse();
  return of(updateUrls(breadcrumbs, []).reverse());
};

/**
 *
 * This function will create breadcrums array if data: { breadcrum: ""} is available
 *
 * @Param activatedRoute
 * @Param url
 * @Param breadcrumbs
 */
function buildBreadCrumb(
  activatedRoute: ActivatedRouteSnapshot,
  breadcrumbs: Array<BreadCrumb> = []
): Array<BreadCrumb> {
  const newBreadcrumbs: Array<BreadCrumb> = [...breadcrumbs];
  // If no routeConfig is avalailable we are on the root path
  if (activatedRoute?.routeConfig?.data?.breadcrumb) {
    // Enable this log if you want to debug
    const label: string = activatedRoute.routeConfig
      ? activatedRoute.routeConfig.data.breadcrumb
      : "";
    let path: string = String("");
    for (const u of activatedRoute.url) {
      path = path + u.path + "/";
    }

    // const params: Params = activatedRoute.params;
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const breadcrumb: BreadCrumb = {
      label,
      url: path,
    };
    newBreadcrumbs.push(breadcrumb);
  }
  if (activatedRoute.parent) {
    // If we are not on our current path yet,
    // there will be more children to look after, to build our breadcumb
    return buildBreadCrumb(activatedRoute.parent, newBreadcrumbs);
  }
  return newBreadcrumbs;
}

function updateUrls(
  breadcrumbs: Array<BreadCrumb>,
  finalBreadCrumbs: Array<BreadCrumb>
): Array<BreadCrumb> {
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return finalBreadCrumbs;
  }
  let path = '/';
  for (const breadcrumb of breadcrumbs) {
    path = path + breadcrumb.url;
  }
  const lastItem: BreadCrumb = breadcrumbs.pop();
  lastItem.url = path;
  finalBreadCrumbs.push(lastItem);
  return updateUrls(breadcrumbs, finalBreadCrumbs);
}
