
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CalioMatPaginatorIntlService extends MatPaginatorIntl {

  private rangeLabel: string;

  constructor(
    private translateService: TranslateService,
    private translateParser: TranslateParser
  ) {
    super();
    this.getTranslations();

    this.translateService.onLangChange.subscribe(() => this.getTranslations());
  }

  getTranslations(): void {
    this.translateService.get([
      "pagination.items_per_page",
      "pagination.next_page",
      "pagination.previous_page",
      "pagination.first_page",
      "pagination.last_page",
      "pagination.range",
    ]).subscribe(translation => {
      this.itemsPerPageLabel = translation['pagination.items_per_page'];
      this.nextPageLabel = translation['pagination.next_page'];
      this.previousPageLabel = translation['pagination.previous_page'];
      this.firstPageLabel = translation['pagination.first_page'];
      this.lastPageLabel = translation['pagination.last_page'];
      this.rangeLabel = translation['pagination.range'];
      this.changes.next();
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return this.translateParser.interpolate(this.rangeLabel, { startIndex: (startIndex+1), endIndex, length });
  }
}
