import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appDynamicDirective]',
    standalone: true
})
export class DynamicDirectiveDirective {

  constructor(
    public viewContainerRef: ViewContainerRef
  ) {}
}
