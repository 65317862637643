import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LoggerService } from '@util-services/logger.service';
import * as CryptoJS from 'crypto-js';

const ENCRYPT_SECRET_KEY = environment.encryptedKey;

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  logoutEvent = new EventEmitter<string>();

  encryptValue(value: string): string {
    const ciphertext = CryptoJS.AES.encrypt(value, ENCRYPT_SECRET_KEY);
    return ciphertext.toString();
  }

  decryptValue(encryptedString: string): string {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedString, ENCRYPT_SECRET_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (e) {
      LoggerService.log('[CryptoService decrypt]', e)
      if (e?.message == 'Malformed UTF-8 data') {
        const redirectionUrl = new URL(window.location.href);
        this.logoutEvent.emit(redirectionUrl.pathname);
      }
    }
  }
}
