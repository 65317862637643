@if (title) {
  <div class="{{ customHeaderClass }}">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title">
        {{ title | translate }}
      </div>
      <button type="button" class="btn-close" aria-label="Close" (keydown.enter)="$event.preventDefault()" (click)="close()">
      </button>
    </div>
    <div class="subtitle">
      {{ subtitle | translate: subtitleTranslationJson }}
    </div>
  </div>
}

@if (title) {
  <hr class="separator"/>
}

<ng-template appDynamicDirective />
