import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { CryptoService } from '@util-services/crypto.service';
import { LocalStorageService } from '@util-services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  protected featuresList: { [key: string]: boolean | number } = {};
  protected masterFeaturesList: { requiredSubscriptionPlan: number }[] = [];

  constructor(
    private alertToastrService: AlertToastrService,
    private localStorageService: LocalStorageService,
    private cryptoService: CryptoService
  ) {
  }

  setUserPermissions(permissions: string[]): void {
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS, this.cryptoService.encryptValue(JSON.stringify(permissions)));
  }

  getUserPermissions(): string[] {
    const encryptedString: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS);
    if (encryptedString) {
      const decryptedString: string = this.cryptoService.decryptValue(encryptedString);
      return JSON.parse(decryptedString);
    } else {
      return [];
    }
  }

  hasPermission(value: string | string[]): boolean {
    if (!value) {
      return true;
    }

    if (typeof value === 'string') {
      value = [value.toString()];
    }

    const permissions = this.getUserPermissions();
    const userPermissions = value as string[];
    const newPermissions = userPermissions.filter(permission => {
      return permissions.indexOf(permission) > -1;
    });

    return (newPermissions?.length > 0);
  }

  permissionNotAvailable(): void {
    this.alertToastrService.showError('general.403_error_message');
  }
}
