import { FormControl } from "@angular/forms";
import { MASTER_PARTNER_SETTINGS, PARTNER_SETTINGS } from "@constants/partner-settings.constants";
import { PartnerSettingDao } from "@db-models/partner-settings.model";

export class PartnerSettingModel {

  constructor(obj?: PartnerSettingDao) {
    if (obj) {
      this.setting_id = obj.id;
      this.value = obj.default_value;
      this.setting = new SettingModel();
      this.setting.key = obj.key;
      this.setting.requires_verification = (obj?.requires_verification !== undefined) ? Number(obj.requires_verification) : 0;
    }
  }

  id: number;
  partner_id: number;
  setting_id: number;
  value: any;
  setting: SettingModel;
  uuid?: string;
  otp: string;
  unverified_value: any;
  has_pending_verification: number = 0;
  type: string;
}

export class SettingModel {
  id: number;
  key: string;
  default_value: string;
  category: string;
  type: string;
  requires_verification: number;
}

export class WhiteLabelSettingsForm {
  custom_email_sender_name = new PartnerSettingModel(MASTER_PARTNER_SETTINGS[PARTNER_SETTINGS.CUSTOM_EMAIL_SENDER_NAME]);
  custom_email_sender_address = new PartnerSettingModel(MASTER_PARTNER_SETTINGS[PARTNER_SETTINGS.CUSTOM_EMAIL_SENDER_ADDRESS]);
  custom_sms_sender_name = new PartnerSettingModel(MASTER_PARTNER_SETTINGS[PARTNER_SETTINGS.CUSTOM_SMS_SENDER_NAME]);
}

export type FormControlMap<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

export interface EmailProviderDetail {
  defaultEmailProviderSetting: string;
  smtpHost: string;
  smtpPort: string;
  smtpPassword: string;
  smtpUsername: string;
  smtpTls: number;
  sendEmailFromTestSystem: number;
}

export type EmailProviderForm = FormControlMap<EmailProviderDetail>;