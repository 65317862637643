import { environment } from "@environments/environment";
import * as Sentry from "@sentry/browser";

const scrubUrl = (urlString: string) => {
  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);

  // List of parameters to scrub
  const sensitiveParams = ['token'];

  // Iterate all the params find sensitives and scrub them
  sensitiveParams.forEach(param => {
    if (params.has(param)) {
      params.set(param, '[REDACTED]');
    }
  });

  // Replace actual params with scrubed one
  url.search = decodeURIComponent(params.toString());

  // Replace event URL with scrubed one
  return url.toString()
}

export const SentryConfig: Sentry.BrowserOptions = {
  environment: 'production',
  release: environment.version,
  dsn: "https://c471f01c38cb3890adc4a1a2c9e2cfb5@o4506308503273472.ingest.sentry.io/4506308509564928",
  beforeSendSpan(span) {
    // Filter out all the span that contains token in description
    if (span?.description?.indexOf('token') >= 0) {
      return null;
    }
    return span;
  },
  beforeSendTransaction(event) {
    // Scrub request URL
    if (event?.request?.url?.indexOf('token') >= 0) {
      event.request.url = scrubUrl(event?.request?.url);
    }

    // Scrub breadcrumbs
    if (event?.breadcrumbs?.length) {
      event.breadcrumbs.forEach(breadcrumb => {
        if (["navigation", "xhr"].includes(breadcrumb?.category)) {
          //  Scrub from, to, and url URLs
          if (breadcrumb?.data?.from?.indexOf('token') >= 0) {
            breadcrumb.data.from = scrubUrl(`${document.location.origin}${breadcrumb.data.from}`);
          }

          if (breadcrumb?.data?.to?.indexOf('token') >= 0) {
            breadcrumb.data.to = scrubUrl(`${document.location.origin}${breadcrumb.data.to}`);
          }

          if (breadcrumb?.data?.url?.indexOf('token') >= 0) {
            breadcrumb.data.url = scrubUrl(`${breadcrumb.data.url}`);
          }
        }
      });
    }

    return event;
  },

  ignoreErrors: [],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['https://dashboard.calenso.com', 'https://my.calenso.com/api', 'https://auth.calenso.com/api'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.8
}
