export const SAFERPAY_AUTHORIZED_STATUS = 'authorized';

export const SAFERPAY_CAPTURED_STATUS = 'captured';

export const SAFERPAY_ERROR_STATUS = [
  "action_not_supported",
  "alias_invalid",
  "amount_invalid",
  "authentication_failed",
  "blocked_by_risk_management",
  "card_check_failed",
  "card_cvc_invalid",
  "card_cvc_required",
  "communication_failed",
  "communication_timeout",
  "condition_not_satisfied",
  "currency_invalid",
  "general_declined",
  "internal_error",
  "no_contract",
  "no_credits_available",
  "payer_authentication_required",
  "paymentmeans_invalid",
  "paymentmeans_not_supported",
  "permission_denied",
  "3ds_authentication_failed",
  "token_expired",
  "token_invalid",
  "transaction_aborted",
  "transaction_already_captured",
  "transaction_declined",
  "transaction_not_started",
  "unexpected_error_by_acquirer",
  "update_card_information",
  "validation_failed",
];

export const SAFERPAY_ERROR_STATUS_MESSAGES = {
  "action_not_supported": "The requested action is not supported in the given context or the action can't be executed with the request data.",
  "alias_invalid": "The alias is not known or already used (in case of registration).",
  "amount_invalid": "The amount does not adhere to the restrictions for this action. E.g. it might be exceeding the allowed capture amount.",
  "authentication_failed": "Wrong password, wrong client certificate, invalid token, wrong HMAC.",
  "blocked_by_risk_management": "Action blocked by risk management.",
  "card_check_failed": "Invalid card number or CVC (this is only returned for the SIX-internal chard check feature for Alias/InsertDirect).",
  "card_cvc_invalid": "Wrong CVC entered.",
  "card_cvc_required": "CVC not entered but required.",
  "communication_failed": "The communication to the processor failed.",
  "communication_timeout": "Saferpay did not receive a response from the external system in time. It is possible that an authorization was created, but Saferpay is not able to know this.",
  "condition_not_satisfied": "The condition which was defined in the request could not be satisfied.",
  "currency_invalid": "Currency does not match referenced transaction currency.",
  "general_declined": "Transaction declined by unknown reason.",
  "internal_error": "Internal error in Saferpay.",
  "no_contract": "No contract available for the brand / currency combination.",
  "no_credits_available": "No more credits available for this account.",
  "payer_authentication_required": "Payer authentication required to proceed (soft decline).",
  "paymentmeans_invalid": "Invalid means of payment (e.g. invalid card).",
  "paymentmeans_not_supported": "Unsupported means of payment (e.g. non SEPA IBAN).",
  "permission_denied": "No permission (e.g. terminal does not belong to the customer).",
  "3ds_authentication_failed": "3D-secure authentication failed, the transaction must be aborted.",
  "token_expired": "The token is expired.",
  "token_invalid": "The token either does not exist for this customer or was already used.",
  "transaction_aborted": "The transaction was aborted by the payer.",
  "transaction_already_captured": "Transaction already captured.",
  "transaction_declined": "Declined by the processor.",
  "transaction_not_started": "The transaction was not started by the payer. Therefore, no final result for the transaction is available.",
  "unexpected_error_by_acquirer": "The acquirer returned an unexpected error code.",
  "update_card_information": "Card details need to be updated in order to have the possibility of a successful payment.",
  "validation_failed": "Validation failed."
}
