import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  get(key: string): string {
    return window.sessionStorage.getItem(key + '_' + environment.version);
  }

  set(key: string, value: string): void {
    window.sessionStorage.setItem(key + '_' + environment.version, value);
  }

  remove(key: string): void {
    window.sessionStorage.removeItem(key + '_' + environment.version);
  }

  clear(): void {
    window.sessionStorage.clear();
  }
}
