import { inject } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { AuthService } from '@feature-services/auth.service';
import { HelperService } from '@util-services/helper.service';

export const AuthGuard = (_route: Route, segments: UrlSegment[]): boolean => {

  const authService = inject(AuthService);
  const router = inject(Router);
  const helperService = inject(HelperService);
  const urlPath = helperService.getUrlPathFromSegments(segments);
  const hasAuthToken = authService.hasAuthToken();
  const redirectionUrl = new URL(window.location.href);

  if (hasAuthToken) {
    // User login and mfa is on than allow to goto mfa page
    if (urlPath.includes('auth/mfa')) {
      return true;
    }

    // Token is there and trying to access auth components than redirect to base route
    if (urlPath.includes('auth')) {
      router.navigate(['/']);
      return false;
    }

    return true;
  } else {
    if (urlPath.includes('auth')) {
      return true;
    }

    // Token is missing or invalid than redirect to login
    if (redirectionUrl.pathname || redirectionUrl.search) {
      authService.logout({ 'login-required': 'true', 'redirect': `${redirectionUrl.pathname}${redirectionUrl.search}` });
    } else {
      authService.logout();
    }
    return false;
  }
}
