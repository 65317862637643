import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { SUBSCRIPTION_CONSTANTS } from '@constants/subscription.constants';
import { SubscriptionModel } from '@db-models/subscribe.model';
import { SubscriptionService } from '@feature-services/subscription.service';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { CryptoService } from '@util-services/crypto.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  protected readonly professionalId = SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_PROFESSIONAL_TYPE.id;
  protected readonly basicId = SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_BASIC_TYPE.id;
  protected readonly businessId = SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_BUSINESS_TYPE.id;
  protected readonly newEnterpriseId = SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_NEW_ENTERPRISE_TYPE.id;

  protected featuresList: { [key: string]: boolean | number } = {};
  protected masterFeaturesList: { requiredSubscriptionPlan: number }[] = [];

  constructor(
    private alertToastrService: AlertToastrService,
    private cryptoService: CryptoService,
    private localStorageService: LocalStorageService,
    private subscriptionService: SubscriptionService
  ) {
  }

  initializeFeature() {
    const subscriptionModel = this.getFeatureFromLocalStorage();
    this.loadSubscriptionFeatures(subscriptionModel);
    this.loadFeaturesMasterList();
  }

  setFeatureInLocalStorage(featureSubscription: SubscriptionModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(featureSubscription)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.FEATURE_KEY, encryptedString);
    LoggerService.log('[Subscription] Features stored in local storage');
  }

  protected getFeatureFromLocalStorage(): SubscriptionModel | null {
    const encrypted = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.FEATURE_KEY) as string;
    if (encrypted) {
      const decrypted = this.cryptoService.decryptValue(encrypted) as string;
      return Object.assign({}, JSON.parse(decrypted)) as SubscriptionModel;
    }
    return null;
  }

  loadSubscriptionFeatures(subscription: SubscriptionModel): void {
    this.featuresList = {};
    if (subscription?.subscription_type?.features) {
      subscription.subscription_type.features.forEach(feature => {
        if (feature.type === 'checkbox') {
          this.featuresList[feature.name] = true;
        } else {
          this.featuresList[feature.name] = Number(feature._joinData.value)
        }
      });
    }
  }

  loadFeaturesMasterList(): void {
    const masterFeaturesList = this.subscriptionService.getFeaturesListFromLocalStorage();
    masterFeaturesList?.forEach(feature => {
      this.masterFeaturesList[feature.name] = {
        requiredSubscriptionPlan: Number(feature.required_subscription_id)
      };
    });
  }

  hasFeature(featureName: string): boolean | number {
    if (featureName) {
      return this.featuresList[featureName] ? this.featuresList[featureName] : false;
    }
    return false;
  }

  featureRequiredSubscriptionPlan(featureName: string): number | null {
    if (featureName) {
      return this.masterFeaturesList[featureName] ? this.masterFeaturesList[featureName]['requiredSubscriptionPlan'] : null;
    }
    return null;
  }

  featureNotAvailable(featureName = null, showToast = true): string | void {
    let message = 'your_subscription_component.feature_not_available_for_subscribed_plan';
    if (featureName) {
      const requiredSubscriptionPlan = this.featureRequiredSubscriptionPlan(featureName);
      switch (requiredSubscriptionPlan) {
        case this.professionalId: message = "your_subscription_component.requires_premium"; break;
        case this.basicId: message = "your_subscription_component.requires_pro"; break;
        case this.businessId: message = "your_subscription_component.requires_business"; break;
        case this.newEnterpriseId: message = "your_subscription_component.requires_enterprise"; break;
        default: message = "your_subscription_component.feature_not_available_for_subscribed_plan"; break;
      }
    }

    if (showToast) {
      this.alertToastrService.showError(message);
      return;
    }
    return message;
  }
}
