import { ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface ComponentLoader {
  loadComponent: () => Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentLoaderService {

  constructor() {
  }

  public loadComponentDynamically(
    vcr: ViewContainerRef,
    loadComponent: () => Promise<any>,
  ): Observable<ComponentRef<any>> {
    vcr.clear();
    return this.forChild(vcr, {
      loadComponent
    });
  }

  private forChild(vcr: ViewContainerRef, cl: ComponentLoader): Observable<ComponentRef<any>> {
    return from(cl.loadComponent()).pipe(
      map((component: any) => vcr.createComponent(component))
    );
  }
}
