<div class="error-container">
  <div class="container">

    <div class="row">
      <div class="col-md-12">
        <div class="mt-5 title">404</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="mt-5 subtitle">{{ 'page_not_found_component.subtitle_1' | translate }}<br>
          {{ 'page_not_found_component.subtitle_2' | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="mt-5 main-text">
          {{ 'page_not_found_component.text' | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center mt-5 mb-5">
          <img src="/assets/images/404_error.svg" class="not-found-image" alt="404 Image">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-5">
        <div class="button">
          <a href="https://www.youtube.com/watch?time_continue=95&v=9gjYZ-2WGOk" target="_blank" class="btn btn-primary next-button">
            {{ 'page_not_found_component.play_video' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
