<ng-progress
  class="progress-bar progress-bar-success"
  id="myProgress"
  #progressBar
  color="#68d6f0"
  min="0"
  speed="200"
  max="100"
  trickleSpeed="300"
  ease="easeInCubic"
  [meteor]="true"
  [spinner]="true"
  [thick]="true"
/>

<router-outlet />
