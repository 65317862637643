/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */
import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import { LoggerService } from '../shared/util-services/logger.service';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: '', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/organizations', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: environment.azureSSO.redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback(_logLevel: LogLevel, message: string, _conainsPii: boolean) {
        LoggerService.log(`[Azure SSO]: ${message}`);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  },
  telemetry: {
    application: {
      appName: "Calenso",
      appVersion: environment.version,
    },
  }
}
