import { Component, DestroyRef, NgZone, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PartnerDbModel } from '@db-models/partner-db.model';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { environment } from '@environments/environment';
import { AuthService } from '@feature-services/auth.service';
import { FeatureService } from '@feature-services/feature.service';
import { PartnerService } from '@feature-services/partner.service';
import { ThemeService } from '@feature-services/theme.service';
import { WorkerService } from '@feature-services/worker.service';
import { TranslateModule } from '@ngx-translate/core';
import { CalioEventsService } from '@util-services/calio-events.service';
import { HelperService } from '@util-services/helper.service';
import { LoggerService } from '@util-services/logger.service';
import { NgProgressModule } from 'ngx-progressbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgProgressModule, TranslateModule]
})
export class AppComponent implements OnInit {

  private partnerService = inject(PartnerService);
  private router = inject(Router);
  private calioEventsService = inject(CalioEventsService);
  private workerService = inject(WorkerService);
  private authService = inject(AuthService);
  private ngZone = inject(NgZone);
  private featureService = inject(FeatureService);
  private destroyRef = inject(DestroyRef);
  private helperService = inject(HelperService);
  private msalAuthService = inject(MsalService);
  private themeService = inject(ThemeService);

  protected readonly resellerWhitelistPaths = ['appointments', 'events', 'customers', 'settings'];

  protected lang: string;
  protected isProd: boolean;
  protected googleTagManagerCode: string;
  protected isIeorEdgeBrowser = false;
  protected loggedInWorker: WorkerDbModel;
  protected partner: PartnerDbModel;
  protected loggedIn = this.authService.hasAuthToken();

  constructor() {
    this.isProd = environment.production;
    this.googleTagManagerCode = environment.googleTagManagerCode;
    this.partnerService.setLangInLocalStorage(null, null);
  }

  ngOnInit(): void {
    this.msalAuthService.handleRedirectObservable().subscribe();
    this.themeService.loadTheme();
    this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
    this.partner = this.partnerService.getPartnerFromLocalStorage();
    
    this.authService.userIsLoggedInEvent
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(worker => {
        this.loggedInWorker = worker;
        this.partner = this.partnerService.getPartnerFromLocalStorage();
        if (this.partner?.subscription) {
          this.featureService.loadSubscriptionFeatures(this.partner.subscription);
          this.featureService.loadFeaturesMasterList();
        }
        this.checkloadFreshDeskWidget();
      });

    this.authService.userLogOutEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.loggedInWorker = undefined;
        this.partner = undefined;
      });

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.helperService.currentUrl = event.url;
          this.calioEventsService.routeChangedEvent$.emit();
        }
      });

    if (this.loggedIn) {
      this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
      this.featureService.initializeFeature();
      this.checkloadFreshDeskWidget();
    }
  }

  private checkloadFreshDeskWidget() {
    // setTimeout is use to wait until loadSubscriptionFeatures set feature value
    setTimeout(() => {
      if (this.featureService.hasFeature('is_freshdesk_enabled')) {
        this.loadFreshDeskWidget();
        // setTimeOut is use to wait until (Hilfe) is initialize
        setTimeout(() => this.appendGaTrackingCode(), 1000);
      }
    });
  }

  private appendGaTrackingCode() {
    LoggerService.log('is_freshdesk_enabled => appendGaTrackingCode');
    try {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` + this.googleTagManagerCode + `');`;
      document.head.appendChild(script);
    } catch (ex) {
      LoggerService.error('Error appending Google Tag Manager');
      LoggerService.error(ex);
    }
  }

  onFreshdeskLoad(): void {
    this.ngZone.run(() => {
      this.initializeFreshdeskAPI();
    });
  }

  private initializeFreshdeskAPI(): void {
    LoggerService.log('Inside initializeFreshdeskAPI()');
    const script = document.createElement('script');
    script.innerHTML = `
        FreshworksWidget('prefill', 'ticketForm', {
        name: '${this.loggedInWorker ? this.loggedInWorker.resource_name : undefined} [${this.loggedInWorker ? this.loggedInWorker.partner_id : undefined}]',
        email: '${this.loggedInWorker ? this.loggedInWorker.email : undefined}',
        type: 'Question',
        custom_fields: {
          cf_order_id: '${this.loggedInWorker ? this.loggedInWorker.partner_id : undefined}'
        }
      });
    `;
    document.body.appendChild(script);
  }

  private loadFreshDeskWidget(): void {
    if (
      window.location.href.indexOf(`${environment.deployUrl}/appointments`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/events`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/survey`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/app/calendar-pro`) > -1
    ) {
      LoggerService.log('[Freshdesk debug] Not loading freshdesk');
    } else {
      const scriptCode = document.createElement('script');
      const scriptTag = document.createElement('script');

      scriptCode.innerHTML = `
        window.fwSettings = {
        'widget_id': 80000000638,
        'locale': 'de'
        };
        !function () {
          if ("function" != typeof window.FreshworksWidget) {
            var n = function () {
              n.q.push(arguments)
            };
            n.q = [], window.FreshworksWidget = n
          }
        }()
      `;
      scriptTag.type = 'text/javascript';
      scriptTag.src = 'https://euc-widget.freshworks.com/widgets/80000000638.js';
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.onload = this.onFreshdeskLoad.bind(this);
      document.body.appendChild(scriptCode);
      document.body.appendChild(scriptTag);
    }
  }
}
