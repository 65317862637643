import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SAFERPAY_AUTHORIZED_STATUS, SAFERPAY_CAPTURED_STATUS, SAFERPAY_ERROR_STATUS, SAFERPAY_ERROR_STATUS_MESSAGES } from '@constants/saferpay-status.constants';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerService } from '@util-services/logger.service';
import { SaferpayService } from '@util-services/saferpay.service';

@Component({
  selector: 'app-saferpay-payment-verification',
  templateUrl: './saferpay-payment-verification.component.html',
  styleUrl: './saferpay-payment-verification.component.scss',
  standalone: true,
  imports: [TranslateModule]
})
export class SaferpayPaymentVerificationComponent {

  paymentSuccess: boolean;
  paymentFailedMessage: string;
  showPaymentVerificationLoader = true;

  private route = inject(ActivatedRoute);
  private saferpayService = inject(SaferpayService);

  constructor() {
    this.route.queryParams.subscribe(params => {
      params['order-uuid'] && this.getOrderPaymentStatus(params['order-uuid']);
    });
  }

  private getOrderPaymentStatus(orderUuid: string): void {
    this.saferpayService.getOrderPaymentStatus(orderUuid).subscribe({
      next: data => {
        if (data?.status) {
          const finalStatus = data.status.toLowerCase();
          if ([SAFERPAY_AUTHORIZED_STATUS, SAFERPAY_CAPTURED_STATUS].includes(finalStatus)) {
            this.paymentSuccess = true;
            this.showPaymentVerificationLoader = false;
          } else if (SAFERPAY_ERROR_STATUS.includes(finalStatus)) {
            this.paymentFailedMessage = SAFERPAY_ERROR_STATUS_MESSAGES[finalStatus] || '';
            this.paymentSuccess = false;
            this.showPaymentVerificationLoader = false;
          } else {
            this.paymentSuccess = false;
            this.showPaymentVerificationLoader = false;
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.paymentSuccess = false;
        this.showPaymentVerificationLoader = false;
        LoggerService.error(error);
      }
    });
  }
}
